<template>
    <div class="box">
        <div class="box-header with-border">
            <h3>
                <previous-route-block/>

                {{ page_title }}

                <button class="btn btn-success pull-right" title="Добавить"
                        type="button" :disabled="load_in_process_modal"
                        v-on:click="loadModal('show-create-review')">
                    <i v-show="load_in_process_modal" class="fas fa-circle-notch fa-spin margin-r-5"/>
                    <i class="fa fa-plus"></i>
                </button>
            </h3>
        </div>

        <div class="box-body">
            <base-modal modal_name="create-review" component="create-review"/>

            <div class="table-responsive">
                <table class="table table-condensed">
                    <thead>
                    <tr style="border-bottom: 1px solid rgb(85 85 85 / 20%);">
                        <th>ID</th>
                        <th>Имя</th>
                        <th>Отзыв</th>
                        <th>Рейтинг</th>
                        <th>Дата</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tr v-for="review in reviews">
                        <td>
                            <strong>{{ review.id }}</strong>
                        </td>
                        <td>
                            <strong>{{ review.name }}</strong>
                        </td>
                        <td style="min-width: 200px;">
                            {{ review.review }}
                        </td>
                        <td>
                            <span style="display: inline-table;">
                                <img v-for="rating_value in rating_values"
                                     v-if="rating_value <= parseInt(review.rating)"
                                     :alt="review.rating"
                                     class="img-fluid"
                                     src="../../assets/img/icons/star.png">
                            </span>
                        </td>
                        <td class="nowrap-column">
                            {{ review.created_date }}
                        </td>
                        <td class="nowrap-column">
                            <button v-if="!review.is_moderate"
                                    class="btn btn-primary" style="margin: 3px;"
                                    title="Принять и опубликовать" type="button"
                                    v-on:click="acceptReview(review.id)">
                                <i class="fa fa-check"></i>
                            </button>

                            <button class="btn btn-danger" style="margin: 3px;"
                                    title="Удалить" type="button"
                                    v-on:click="removeReview(review.id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr v-if="reviews.length === 0">
                        <td class="bg-white" colspan="10">
                            <p class="mt-5 mb-5 text-muted text-center">
                                Нет элементов для отображения
                            </p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import BaseModal from './../../components/base-modal';

    Vue.component('create-review', (resolve) => {
        return require(['./create'], resolve);
    });

    export default {
        name: 'reviews',

        components: {
            BaseModal,
        },

        metaInfo() {
            return {
                title: this.page_title,
            }
        },

        data: () => ({
            page_title: 'Отзывы',

            relations: [
                'city',
            ],

            reviews: [],

            rating_values: [
                1, 2, 3, 4, 5
            ],

            load_in_process_modal: false,
        }),

        async created() {
            try {
                this.EventBus.$on('update-reviews', await this.getReviews);

                await this.getReviews();
            } catch (error) {
                errorHandler(error);
            }
        },

        methods: {
            async getReviews() {
                try {
                    const reviews_response = await API.apiClient.get('/reviews', {
                        params: {
                            relations: this.relations,
                            sorting: 'desc'
                        },
                    });

                    this.reviews = reviews_response.data;
                } catch (error) {
                    errorHandler(error);
                }
            },

            acceptReview(reviewId) {
                API.apiClient.put('/reviews/accept/' + reviewId).then(() => {
                    this.getReviews();
                    showSuccess();
                }).catch((error) => {
                    errorHandler(error);
                });
            },

            removeReview(reviewId) {
                this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
                    API.apiClient.delete('/reviews/' + reviewId).then(() => {
                        this.getReviews();
                        showSuccess();
                    }).catch((error) => {
                        errorHandler(error);
                    });
                });
            },

            async loadModal(eventModalName, eventData) {
                this.load_in_process_modal = true;

                await this.EventBus.$emit(eventModalName, eventData);

                this.EventBus.$on('is_loaded', () => {
                    this.load_in_process_modal = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>