<template>
    <modal :adaptive="is_adaptive"
           :clickToClose="true"
           :draggable="is_draggable"
           :name="modal_name"
           :resizable="is_resizable"
           :scrollable="is_scrollable"
           :shiftX="shift_x"
           :shiftY="shift_y"
           :height="height"
           overlayTransition="fade"
           transition="fade">
        <div :class="classes_list" :style="styles_list">
            <component :is="component" v-bind:$_ModalObject="item" v-bind:$_ModalObjectItems="items"/>
        </div>
    </modal>
</template>

<script>
    import Vue from "vue";
    import VModal from 'vue-js-modal';

    Vue.use(VModal);

    export default {
        name: 'base-modal',

        props: {
            items: {
                type: [Array, Object, null],
                required: false,
                default: null,
            },
            modal_name: {
                type: String,
                required: true,
            },
            component: {
                type: String,
                required: true,
            },
            is_adaptive: {
                type: Boolean,
                required: false,
                default: true,
            },
            is_draggable: {
                type: Boolean,
                required: false,
                default: false,
            },
            is_resizable: {
                type: Boolean,
                required: false,
                default: false,
            },
            is_scrollable: {
                type: Boolean,
                required: false,
                default: true,
            },
            shift_x: {
                type: Number,
                required: false,
                default: 0.5,
            },
            shift_y: {
                type: Number,
                required: false,
                default: 0.5,
            },
            height: {
                type: [String, Number],
                required: false,
                default: 'auto',
            },
            classes_list: {
                type: String,
                required: false,
                default: 'box box-primary box-modal mb-0'
            },
            styles_list: {
                type: String,
                required: false,
            },
        },

        data() {
            return {
                item: null,
            }
        },

        async created() {
            try {
                await this.EventBus.$on('show-' + this.modal_name, (item, items) => {
                    if (item) {
                        this.item = item;
                    }
                    this.debounceShow();
                });
                await this.EventBus.$on('hide-' + this.modal_name, this.hide);

                this.debounceShow = _.debounce(this.show, 50);
            } catch (error) {
                errorHandler(error);
            }
        },

        methods: {
            async show() {
                this.$modal.show(this.modal_name);
            },
            async hide() {
                this.$modal.hide(this.modal_name);
            },
        }
    }
</script>

<style scoped>

</style>